<template>
  <div class="notification">
    <notification-list></notification-list>
  </div>
</template>

<script>
import NotificationList from '@/components/menu/notification/list'

export default {
  components: { NotificationList }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.notification {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
}
</style>
