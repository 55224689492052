<template>
  <div class="list">
    <div class="list__item" v-for="notice in notices" :key=notice.id>
      <p class="list__item__date">{{ formated(notice.createdAt.toDate()) }}<span v-if="unreadNoticeIDs.includes(notice.id)" class="list__item__date__unread"></span></p>
      <p :class="unreadNoticeIDs.includes(notice.id) ? 'list__item__content--unread' : 'list__item__content'">{{ notice.content }}</p>
      <div class="list__item__category">{{ notice.type === 'その他' ? 'Nội dung khác' : 'Đăng tải video' }}</div>
    </div>
    <div v-if="notices.length === 0" class="list__none">
      <img class="list__none__img" src="@/assets/img/settings-gray.svg">
      <p class="list__none__text">Bạn không có tin nhắn nào.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommonFunction from '@/lib/common'

export default {
  extends: CommonFunction,
  data () {
    return {
      // 未読お知らせのID一覧
      unreadNoticeIDs: [],
      // お知らせ情報を追加中
      isAdding: false
    }
  },
  mounted () {
    this.unreadNoticeIDs = this.getUnreadNoticeIDs()()

    // 未読を既読に変更
    if (this.unreadNoticeIDs.length > 0) {
      const promises = []
      this.unreadNoticeIDs.forEach(id => {
        const notice = this.notices.filter(notice => notice.id === id)[0]
        const readed = notice.readed.concat([this.uid])
        promises.push(this.updateNotice({
          id: id,
          params: { readed: readed }
        }))
      })
      Promise.all(promises)
    }

    // スクロールイベントを追加
    window.addEventListener('scroll', this.onScroll)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Objects} お知らせ情報
     */
    notices () {
      return this.getNotices()()
    }
  },
  methods: {
    ...mapGetters('notice', ['getNotices', 'getUnreadNoticeIDs']),
    ...mapActions('notice', ['addNotices', 'updateNotice']),
    /**
     * ページ下部に移動したらお知らせ情報を取得する
     */
    onScroll () {
      if (this.notices / 12 === 0 && !this.isAdding && this.isBottom()) {
        this.isAdding = true
        this.addNotices(this.uid).then(() => {
          this.isAdding = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.list {
  width: 100%;
  &__item {
    padding: 10px 0 24px 0;
    border-bottom: 1px solid #d2d2d2;
    &__date {
      margin: 0;
      font-size: 12px;
      font-weight: bold;
      color: #393939;
      &__unread {
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-left: 7px;
        border-radius: 50%;
        background-color: #ff9534;
      }
    }
    &__content {
      margin: 0;
      font-size: 12px;
      color: #707070;
      &--unread {
        @extend .list__item__content;
        font-weight: bold;
        color: #393939;
      }
    }
    &__category {
      float: right;
      width: 96px;
      height: 14px;
      border-radius: 20px;
      background-color: #a5a5a5;
      font-size: 10px;
      line-height: 14px;
      color: white;
      text-align: center;
    }
  }
  &__none {
    margin-top: 138px;
    text-align: center;
    &__img {
      width: 151.1px;
    }
    &__text {
      margin: 0;
      margin-top: 10.4px;
      font-size: 15px;
      font-weight: bold;
      color: #d4d4d4;
    }
  }
}
</style>
